import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function HeadNavbar() {
  const { t } = useTranslation();
  return (
    <div className="Select_navbar">
      <Navbar expand="lg">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto4">
            <Link className="Link" to="/">
              {t("menu.Home")}
            </Link>
            <Link className="Link" to="/Island">
              {t("menu.ISland")}
            </Link>
            <Link className="Link" to="/Product">
              {t("menu.Product")}
            </Link>
            <Link className="Link" to="/About">
              {t("menu.About")}
            </Link>
            <Link className="Link" to="/Contact">
              {t("menu.Contact")}
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
