import React from "react";
import { Row, Col, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
export default function Product() {
  const { t } = useTranslation();
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="Product">
        <Row className="Head_Product gx-0">
          <Col sm={6} className="boxline">
            <img className="line" src="/Img/line_trip.png" alt="#" />
          </Col>
          <Col sm={3} className="product_topic">
            <h1>{t("Product.titie")}</h1>
          </Col>
        </Row>
        <Row className="Detail_Trip gx-0">
          <Col sm={4} className="Box_Trip">
            <Link to="/Daytrip">
              <Col sm={12} className="Daytrip">
                <div class="Icon_Daytrip">
                  <Figure.Image
                    width={50}
                    height={50}
                    src="/Img/Icon/daytrip_blue.png"
                  />
                  <br />
                  <b>{t("Daytrip")}</b>
                  <div class="text_overlay">
                    <p>{t("Product.Clicktoshow_allprogram")}</p>
                  </div>
                </div>
              </Col>
            </Link>
          </Col>
          <Col sm={4} className="Box_Trip">
            <Link to="/Overnight">
              <Col sm={12} className="Overnight">
                <div class="Icon_Overnight">
                  <Figure.Image
                    width={50}
                    height={50}
                    src="/Img/Icon/overnight_blue.png"
                  />

                  <br />
                  <b>{t("Overnight")}</b>
                  <div class="text_overlay">
                    <p>{t("Product.Clicktoshow_allprogram")}</p>
                  </div>
                </div>
              </Col>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}
