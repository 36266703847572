import React from "react";
import { Col, Figure, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import "./Trip.scss";

export default function Island11() {
  const { t } = useTranslation();
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="Island">
        <Row sm={12} className="Head_Island11 gx-0">
          <Col sm={5}>
            <h1>{t("Island.Name_Similan")}</h1>
          </Col>
          <Col sm={3} className="text_overlay">
            <b>{t("Island.Detail_Similan")}</b>
          </Col>
        </Row>

        <h2>
          {t("PRODUCT_HAVE")} {t("Island.Name_Similan")}
        </h2>

        <div className="detail-island gx-0">
          <Link to="/Similan2D1N" className="detail-trip">
            <div className="photo-overlay">
              <div className="island-similan2d1n">
                <div class="text-overlay">
                  <div class="trip">
                    <Figure.Image
                      width={50}
                      height={50}
                      src="/Img/Icon/overnight.png"
                    />
                    <br />
                    <b>{t("Overnight")}</b>
                  </div>
                </div>
              </div>
            </div>
            <p>{t("Overnight.Name_Similan2D1N")}</p>
          </Link>
          <Link to="/Similan" className="detail-trip">
            <div className="photo-overlay">
              <div className="island-similan">
                <div class="text-overlay">
                  <div class="trip">
                    <Figure.Image
                      width={50}
                      height={50}
                      src="/Img/Icon/daytrip.png"
                    />
                    <br />
                    <b>{t("Daytrip")}</b>
                  </div>
                </div>
              </div>
            </div>
            <p>{t("Home.Similan")}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
