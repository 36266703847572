import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { BsGlobe } from "react-icons/bs";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Dropdown = styled.div`
  
`

export default function HeadNavbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    console.log(i18n.language)
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="Headnavbar">
      <Navbar expand={false}>
        <div className="Offcanvas_menu">
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas id="offcanvasNavbar" placement="start">
            <Offcanvas.Header closeButton style={{ fontSize: "30px" }}></Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="Offcanvas_link">
                <Link to="/">
                  <h1>{t("menu.Home")}</h1>
                </Link>
                <Link to="/Island">
                  <h1>{t("menu.ISland")}</h1>
                </Link>
                <Link to="/Product">
                  <h1>{t("menu.Product")}</h1>
                </Link>
                <Link to="/About">
                  <h1>{t("menu.About")}</h1>
                </Link>
                <Link to="/Contact">
                  <h1>{t("menu.Contact")}</h1>
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
        <div className="boxnav">
          <Navbar.Brand href="/">
            <img src="/logo.png" alt="logo" />
          </Navbar.Brand>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "5px" }}>
            <BsGlobe style={{ fontSize: "20px" }} />
          </div>
          <Dropdown className="boxnav" >
            <select
              name="languages"
              style={{ border: "0px", color: "#144d68", background: "white" }}
              onChange={changeLanguage}
              value={i18n.language}
            >
              <option value="en" name="language">
                English
              </option>
              <option value="th" name="language">
                ภาษาไทย
              </option>
            </select>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
}
