import React, { useState, useEffect } from "react";
import { Col, Figure, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import Loading from "../component/Loading";
import { Scroll } from "../component/Scroll";

export default function Overnight() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const { lockScroll, unlockScroll } = Scroll();

  useEffect(() => {
    if (loading === true) {
      lockScroll();
      setTimeout(() => setLoading(false), 2000);
    } else {
      unlockScroll();
    }
  });

  let settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          rows: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          rows: 3,
        },
      },
    ],
  };
  return (
    <div>
      {loading === false ? <></> : <Loading />}
      <HeadNavbar />
      <SelectNavbar />
      <div className="Overnig_trip">
        <Link to="/Daytrip" className="Button_Daytrip">
          <div className="BoxDaytrip">
            <h2>
              <Figure.Image
                width={50}
                height={50}
                src="/Img/Icon/daytrip_blue.png"
              />{" "}
              {t("Daytrip")}
            </h2>
            <div class="text_overlay">
              <p>{t("Clicktoseedetail")}</p>
            </div>
          </div>
        </Link>
        <div className="Accordion_Trip">
          <Link to="/AccordionTrip" style={{ textDecoration: "none" }}>
            <Row className="Accordion_Overnight gx-0">
              <Col sm={6}>
                <h1>
                  <Figure.Image
                    width={100}
                    height={100}
                    src="/Img/Icon/overnight.png"
                  />{" "}
                  {t("Overnight")}
                </h1>
              </Col>
            </Row>
          </Link>

          <Slider {...settings} className="Detail_Island1 gx-0">
            <div className="detail_trip_overnight">
              <div className="photo_overlay">
                <Link to="/Similan2D1N">
                  <div className="Island_Similan2D1N"></div>{" "}
                </Link>
              </div>
              <p>{t("Overnight.Name_Similan2D1N")}</p>
            </div>

            <div className="detail_trip_overnight">
              <div className="photo_overlay">
                <Link to="/Surin2D1N">
                  <div className="Island_Surin2D1N"></div>{" "}
                </Link>
              </div>
              <p>{t("Overnight.Name_Surin2D1N")}</p>
            </div>
            <div className="detail_trip_overnight">
              <div className="photo_overlay">
                <Link to="/Surin3D2N">
                  <div className="Island_Surin3D2N"></div>
                </Link>
              </div>
              <p>{t("Overnight.Name_Surin3D2N")}</p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
