import React from "react";
import { Col, Figure, Row } from "react-bootstrap";
import {
  TimelineContent,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../../component/SelectNavbar";
import HeadNavbar from "../../component/HeadNavbar";
import "../TripCarousel.scss";

export default function Similan() {
  const { t } = useTranslation();
  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    lazyLoad: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1101,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="Detail_Daytrip">
        <div className="Accordion_Trip">
          <Row className="Accordion_Daytrip gx-0">
            <Col sm={6}>
              <h1>
                <Figure.Image
                  width={100}
                  height={100}
                  src="/Img/Icon/daytrip.png"
                />{" "}
                {t("Daytrip")}
              </h1>
            </Col>
          </Row>
        </div>
        <div className="Headname_Island">
          <h1>{t("Trip.titlename_Similan")}</h1>
        </div>
        <div className="carousel-photo">
          <Slider {...settings}>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo1.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo2.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo3.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo4.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo5.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo6.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Daytrip/Similan/photo7.jpg" alt="" />
            </div>
          </Slider>

          <div className="recommend">
            <h4>{t("RECOMMEND")}</h4>
            <Row className="recommend-trip">
              <Col sm={6}>
                <div className="detail-island gx-0">
                  <Link to="/Rok_Haa" className="detail-trip">
                    <div className="photo-overlay">
                      <div className="island-rok-haa"></div>
                    </div>
                    <p>{t("Daytrip.Name_Rok_Haa")}</p>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="detail-island gx-0">
                  <Link to="/Surin" className="detail-trip">
                    <div className="photo-overlay">
                      <div className="island-surin"></div>
                    </div>
                    <p>{t("Daytrip.Name_Surin")}</p>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="other-trip">
            <a href="/Product">{t("Other Trips")}</a>
          </div>
        </div>
        <div className="detail_trips">
          <Row>
            <Col sm={6}>
              <Timeline className="timeline">
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>08.30AM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_08.30AM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>09.00AM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_09.00AM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>10.50AM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_10.50AM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>11.40AM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_11.40AM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>12.00PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_12.00PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>01.00PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_01.00PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>02.15PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_02.15PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>03.30PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_03.30PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>04.50PM.</b>
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Similan_04.50PM")}</p>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Col>
          </Row>
        </div>
        <div className="Detailname_Island">
          <Col sm={6}>
            <h1>{t("Trip.Condition")}</h1>
          </Col>
        </div>
        <div className="detail_trips">
          <Row>
            <Col sm={6} style={{ padding: "2%" }}>
              <h4 style={{ paddingLeft: "3%" }}>{t("Tour_include")} :</h4>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/land.png"
                    />{" "}
                    {t("include_transfer")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/speedboad.png"
                    />{" "}
                    {t("include_transfer_by_speed")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/dive.png"
                    />{" "}
                    {t("include_Snorkeling")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/coffee.png"
                    />{" "}
                    {t("include_drink")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/lunch.png"
                    />{" "}
                    {t("include_3meals")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/EP_guide.png"
                    />{" "}
                    {t("include_Professionalguide")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/cpr.png"
                    />{" "}
                    {t("include_CPR")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/accident.png"
                    />{" "}
                    {t("include_accident_insurance")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/towel.png"
                    />{" "}
                    {t("include_Fin&Towel")}
                  </p>
                </li>
                <li>
                  <p>
                    <Figure.Image
                      width={20}
                      height={20}
                      src="/Img/Icon/fee.png"
                    />{" "}
                    {t("include_park_fee")}
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="Detailname_Island">
          <Col sm={6} className="whattobring">
            <h1>{t("What_to_bring")} : </h1>
            <p>{t("What_to_bring_detail1")}</p>
          </Col>
        </div>
        <div className="recommend-mobile-size">
          <h4>{t("RECOMMEND")}</h4>
          <Row className="recommend-trip-mobile-size">
            <Col>
              <div className="detail-island-mobile-size gx-0">
                <Link to="/Rok_Haa" className="detail-trip-mobile-size">
                  <div className="photo-overlay-mobile-size">
                    <div className="island-rok-haa-mobile-size"></div>
                  </div>
                  <p>{t("Daytrip.Name_Rok_Haa")}</p>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="detail-island-mobile-size gx-0">
                <Link to="/Surin" className="detail-trip-mobile-size">
                  <div className="photo-overlay-mobile-size">
                    <div className="island-surin-mobile-size"></div>
                  </div>
                  <p>{t("Daytrip.Name_Surin")}</p>
                </Link>
              </div>
            </Col>
          </Row>
          <Link to="/Product" className="other-trip">
            {t("Other Trips")}
          </Link>
        </div>
      </div>
    </div>
  );
}
