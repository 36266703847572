import React from "react";
import { Row, Col, Figure } from "react-bootstrap";
import { GrMail } from "react-icons/gr";
import { BsTelephoneFill, BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Body = styled.div`
  background-color: #000;
  opacity: 0.8;
  color: #fff;
  position: relative;
  display: block;
  overflow: hidden;
  text-shadow: 2px 2px #000;
  .background-image {
    opacity: 0.4;
    background-image: url("/Img/bgfooter.png");
    z-index: -5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .list-unstyled {
    li {
      p {
        margin: 0;
        color: white;
      }
    }
  }
  .link {
    color: white;
    text-decoration: none;
  }
`;

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Body className="footer">
      <div className="background-image" />
      <Row className="boxfooter">
        <Col sm={3}>
          <Figure>
            <Figure.Image alt="whitelogo" src="/Img/whitelogo.png" />
          </Figure>
        </Col>
        <Col sm={3} className="list-detail-contact">
          <h5>{t("Contact.title")}</h5>
          <ul className="list-unstyled">
            <li>
              <p>
                <GrMail /> info@seastarandaman.com
              </p>
            </li>
            <li>
              <p>
                <BsTelephoneFill /> 093 582 2897
              </p>
            </li>
            <li>
              <a
                href="https://www.instagram.com/seastar_andaman/?hl=th"
                style={{ color: "white", textDecoration: "none" }}
              >
                <p>
                  <AiFillInstagram /> seastar_andaman
                </p>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/SeastarHappyJourney"
                style={{ color: "white", textDecoration: "none" }}
              >
                <p>
                  <BsFacebook /> Seastar Happy Journey
                </p>
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={3} className="list-detail-address">
          <h5>SEASTAR ANDAMAN</h5>
          <ul className="list-unstyled">
            <li style={{ width: "89%" }}>
              <p>{t("Footer.detail")}</p>
              <br />
              <p>{t("Footer.address")}</p>
              <p>Tel. 076 350 144</p>
              <p>Fax 076 350 145</p>
            </li>
          </ul>
        </Col>
        <Col sm={3} className="list-detail-bulletin">
          <div className="linkpage">
            <div>
              <ul>
                <li>
                  <Link to="/" className="link">
                    {t("menu.Home")}
                  </Link>
                </li>
                <li>
                  <Link to="/Product" className="link">
                    {t("menu.Product")}
                  </Link>
                </li>
                <li>
                  <Link to="/About" className="link">
                    {t("menu.About")}
                  </Link>
                </li>
                <li>
                  <Link to="/Contact" className="link">
                    {t("menu.Contact")}
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <Link to="/Daytrip" className="link">
                    {t("Daytrip")}
                  </Link>
                </li>
                <li>
                  <Link to="/Overnight" className="link">
                    {t("Overnight")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Body>
  );
}
