import Provider from "./axios";

export const sendMail = async (data) => {
  console.log(data);
  const result = await Provider({
    type: "POST",
    url: "/mail/sendMail",
    data,
  });
  console.log(result);
  return result;
};
