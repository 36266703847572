import React from 'react'

export const Scroll = () => {

    const lockScroll = React.useCallback(() => {
        document.body.style.overflow = 'hidden';
    }, [])

    const unlockScroll = React.useCallback(() => {
        document.body.style.overflow = '';
    }, [])

    return {
        lockScroll,
        unlockScroll
    };
}
