import React, { useState, useEffect } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import Loading from "../component/Loading";
import { Scroll } from "../component/Scroll";

export default function Island() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { lockScroll, unlockScroll } = Scroll();

  useEffect(() => {
    if (loading === true) {
      lockScroll();
      setTimeout(() => setLoading(false), 2500);
    } else {
      unlockScroll();
    }
  });

  return (
    <div>
      <div>
        {loading === false ? <></> : <Loading />}
        <HeadNavbar />
        <SelectNavbar />
        <div className="Islands">
          <Row className="Head_Islands gx-0">
            <h1>{t("Island.Title")}</h1>
            <Col sm={8} className="line">
              <img src="/Img/line_trip.png" alt="#" />
            </Col>
            <Col sm={3} className="textt">
              <b style={{ fontSize: "25px", backgroundColor: "white" }}>
                {t("Island.subTitle")}
              </b>
            </Col>
          </Row>
          <Carousel fade className="Carousel_trip1">
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island1 ">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_JamesBond")}</h3>
                    <b>{t("Island.Detail_JamesBond")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island1">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island2 ">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Khai")}</h3>
                    <b>{t("Island.Detail_Khai")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island2">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island3 ">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Bamboo")}</h3>
                    <b>{t("Island.Detail_Bamboo")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island3">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island4">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_MayaBay")}</h3>
                    <b>{t("Island.Detail_MayaBay")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island4">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island5">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Pileh")}</h3>
                    <b>{t("Island.Detail_Pileh")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island5">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <div className="island Head_Island6">
                <div class="detailtrip_overlay">
                  <h3>Phi Phi Islands (Bamboo)</h3>
                  <b>
                    Visit the most famous island in Thailand, Phi Phi Islands.
                    Enjoy a beautiful view and landscape of Phi Phi Islands.
                    Experience the scene of the movie “The Beach” at Maya Bay.
                    Enjoy taking pictures in Pileh Lagoon with the cliff and
                    clear water. Relaxing and sunbathing on Bamboo Island which
                    has white sand beach and crystal-clear sea water.
                  </b>
                  <div style={{ textAlign: "center" }}>
                    <Link to="/Island6">
                      <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                        <b>See more detail</b>
                      </button>
                    </Link>
                  </div>
                </div>
              </div> */}

                <div className="island Head_Island7">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_RayaNoi")}</h3>
                    <b>{t("Island.Detail_RayaNoi")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island7">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="island Head_Island8">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_RayaYai")}</h3>
                    <b>{t("Island.Detail_RayaYai")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island8">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="island Head_Island10">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Rok")}</h3>
                    <b>{t("Island.Detail_Rok")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island10">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island11">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Similan")}</h3>
                    <b>{t("Island.Detail_Similan")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island11">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="island Head_Island12">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Haa")}</h3>
                    <b>{t("Island.Detail_Haa")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island12">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="island Head_Island13">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Surin")}</h3>
                    <b>{t("Island.Detail_Surin")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island13">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
          <Carousel fade className="Carousel_trip2">
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island1 ">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_JamesBond")}</h3>
                    <b>{t("Island.Detail_JamesBond")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island1">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island2 ">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Khai")}</h3>
                    <b>{t("Island.Detail_Khai")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island2">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island3 ">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Bamboo")}</h3>
                    <b>{t("Island.Detail_Bamboo")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island3">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island4">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_MayaBay")}</h3>
                    <b>{t("Island.Detail_MayaBay")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island4">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island5">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Pileh")}</h3>
                    <b>{t("Island.Detail_Pileh")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island5">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="island Head_Island7">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_RayaNoi")}</h3>
                    <b>{t("Island.Detail_RayaNoi")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island7">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island8">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_RayaYai")}</h3>
                    <b>{t("Island.Detail_RayaYai")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island8">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="island Head_Island9">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Maiton")}</h3>
                    <b>{t("Island.Detail_Maiton")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island9">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div> */}
                <div className="island Head_Island10">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Rok")}</h3>
                    <b>{t("Island.Detail_Rok")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island10">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="island Head_Island11">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Similan")}</h3>
                    <b>{t("Island.Detail_Similan")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island11">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="CarouselIMG">
                <div className="island Head_Island12">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Haa")}</h3>
                    <b>{t("Island.Detail_Haa")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island12">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="island Head_Island13">
                  <div class="detailtrip_overlay">
                    <h3>{t("Island.Name_Surin")}</h3>
                    <b>{t("Island.Detail_Surin")}</b>
                    <div style={{ textAlign: "center" }}>
                      <Link to="/Island13">
                        <button class="btnSeemore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2">
                          <b>{t("Island.buttonSeemore")}</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
