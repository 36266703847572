import React from 'react'

export function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className}
            style={{ ...style, display: "block", width: "5rem", height: "3rem", right: "-65px" }}
            onClick={onClick}
            src="/Img/next.svg" alt="no-img" />
    );
}

export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            className={className}
            style={{ ...style, display: "block", width: "5rem", height: "3rem", left: "-65px" }}
            onClick={onClick}
            src="/Img/previous.svg" alt="no-img" />
    );
}