import React from "react";
import { Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
export default function Accordion_Trip() {
  const { t } = useTranslation();
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="Accordion_Selecttrip">
        <Link to="/Daytrip" className="Button_Daytrip">
          <div className="BoxDaytrip">
            <h2>
              <Figure.Image
                width={50}
                height={50}
                src="/Img/Icon/daytrip_blue.png"
              />{" "}
              {t("Daytrip")}
            </h2>
            <div class="text_overlay">
              <p>{t("Clicktoseedetail")}</p>
            </div>
          </div>{" "}
        </Link>
        <Link to="/Overnight" className="Button_Overnight">
          <div className="BoxOvernight">
            <h2>
              <Figure.Image
                width={50}
                height={50}
                src="/Img/Icon/overnight_blue.png"
              />{" "}
              {t("Overnight")}
            </h2>
            <div class="text_overlay">
              <p>{t("Clicktoseedetail")}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
