import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import MailSent from "../component/MailSend";
import { sendMail } from "../sendMail";
import { Scroll } from "../component/Scroll";

export default function Contact() {
  const { t } = useTranslation();

  const [notOnLoad, setNotOnLoad] = useState(true);
  const [mailStatus, setmailStatus] = useState("sending");
  const { lockScroll, unlockScroll } = Scroll();

  const SendingMail = () => {
    lockScroll();
    setTimeout(() => setNotOnLoad(true), 3000);
    unlockScroll();
  };

  const [currentName, setName] = useState("");
  const [currentEmail, setEmail] = useState("");
  const [currentTel, setTel] = useState("");
  const [currentMessage, setMessage] = useState("");

  const onSubmitEmail = async (name, email, tel, message) => {
    console.log(name, email, tel, message);
    const dataSendMail = await sendMail({
      name,
      email,
      phoneNum: tel,
      message,
    });
    if (dataSendMail.status === "success") {
      console.log("sendmail success");
    }
  };

  function prevent(e) {
    console.log("injected prevent defalut");
    e.preventDefault();
  }

  return (
    <div>
      {notOnLoad === true ? <></> : <MailSent status={mailStatus} />}
      <HeadNavbar />
      <SelectNavbar />
      <div className="Contact">
        <Row>
          <Col className="py-5 ">
            <Row className=" no-gutters">
              <Row>
                <div className="col-lg-8 ">
                  <div className="contact-box ml-3">
                    <form className="mt-4">
                      <Row>
                        <Col sm={6}>
                          <div className="title_Contact">
                            <h1 lg="2">{t("Contact.title")}</h1>
                            <h3 className="font-weight-light mt-2">
                              {t("Seastar_andaman")}
                            </h3>
                          </div>
                        </Col>
                        <Col className="form_contact" sm={6}>
                          <div className="col-lg-12">
                            <h4 className="font-weight-light mt-2">
                              {t("Contact.name")}
                            </h4>
                            <div className="form-group mt-2">
                              <input
                                name="Full Name"
                                className="form-control"
                                type="text"
                                placeholder={t("Contact.Detail_name")}
                                value={currentName}
                                onChange={(e) => setName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <br />

                          <div className="col-lg-12">
                            <h4 className="font-weight-light mt-2">
                              {t("Contact.email")}
                            </h4>
                            <div className="form-group mt-2">
                              <input
                                name="Email"
                                className="form-control"
                                type="email"
                                placeholder={t("Contact.Detail_email")}
                                value={currentEmail}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <br />

                          <div className="col-lg-12">
                            <h4 className="font-weight-light mt-2">
                              {t("Contact.number")}
                            </h4>
                            <div className="form-group mt-2">
                              <input
                                id="Phone_number"
                                name="Phone number"
                                className="form-control"
                                type="text"
                                placeholder={t("Contact.Detail_number")}
                                value={currentTel}
                                onChange={(e) => setTel(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <br />

                          <div className="col-lg-12">
                            {" "}
                            <h4 className="font-weight-light mt-2">
                              {t("Contact.massage")}
                            </h4>
                            <div className="form-group mt-2">
                              <textarea
                                name="Massage"
                                className="form-control"
                                rows="3"
                                placeholder={t("Contact.Detail_massage")}
                                value={currentMessage}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                              ></textarea>
                            </div>
                          </div>
                          <div className="btn col-lg-12">
                            <br />
                            <button
                              onClick={(e) => {
                                prevent(e);
                                onSubmitEmail(
                                  currentName,
                                  currentEmail,
                                  currentTel,
                                  currentMessage
                                );
                                setNotOnLoad(!notOnLoad);
                                SendingMail();
                              }}
                              className="btnContact btn-danger-gradiant mt-3 text-white border-0 px-3 py-2"
                            >
                              <span>{t("Contact.button")}</span>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
