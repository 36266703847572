import React, { useState, useEffect } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import Loading from "../component/Loading";
import { Scroll } from "../component/Scroll";

export default function Home() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { lockScroll, unlockScroll } = Scroll();

  useEffect(() => {
    if (loading === true) {
      lockScroll();
      setTimeout(() => setLoading(false), 3000);
    } else {
      unlockScroll();
    }
  });

  return (
    <div>
      {loading === false ? <></> : <Loading />}
      <HeadNavbar />
      <SelectNavbar />
      <div className="Home">
        <Carousel fade>
          <Carousel.Item interval={2000}>
            <Link to="/Island3">
              <img className="home-image" src="./Img/1.png" alt="First slide" />
              <div className="text_home">
                <h1>Seastar Andaman</h1>
                <h3>HAPPY JOURNEY</h3>
              </div>
              <div className="NameIsland_home">
                <h4>{t("Home.PhiPhi_Bamboo")}</h4>
                <Row className="Line_detail">
                  <Col sm={6}>
                    <b>{t("Home.subtitlePhiPhi_Bamboo")}</b>
                  </Col>
                  <Col sm={6}>
                    <img
                      src="/Img/Homeline.png"
                      alt="#"
                      style={{ width: "115%", marginLeft: "-30%" }}
                    />
                  </Col>
                </Row>
              </div>
            </Link>
          </Carousel.Item>

          <Carousel.Item interval={2000}>
            <Link to="/Island7">
              <img
                className="home-image"
                src="./Img/2.png"
                alt="Second slide"
              />
              <div className="text_home">
                <h1>Seastar Andaman</h1>
                <h3>HAPPY JOURNEY</h3>
              </div>
              <div className="NameIsland_home">
                <h4>{t("Home.Raya_Noi")}</h4>
                <Row className="Line_detail">
                  <Col sm={7}>
                    <b>{t("Home.subtitleRaya_Noi")}</b>
                  </Col>
                  <Col sm={5}>
                    <img
                      src="/Img/Homeline.png"
                      alt="#"
                      style={{ width: "95%", marginLeft: "-13%" }}
                    />
                  </Col>
                </Row>
              </div>
            </Link>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <Link to="/Island10">
              <img className="home-image" src="./Img/3.png" alt="Third slide" />
              <div className="text_home">
                <h1>Seastar Andaman</h1>
                <h3>HAPPY JOURNEY</h3>
              </div>
              <div className="NameIsland_home">
                <h4>{t("Home.Rok")}</h4>
                <Row className="Line_detail">
                  <Col sm={6}>
                    <b>{t("Home.subtitleRok")}</b>
                  </Col>
                  <Col sm={6}>
                    <img
                      src="/Img/Homeline.png"
                      alt="#"
                      style={{ width: "145%", marginLeft: "-63%" }}
                    />
                  </Col>
                </Row>
              </div>
            </Link>
          </Carousel.Item>

          <Carousel.Item interval={2000}>
            <Link to="/Island11">
              <img className="home-image" src="./Img/4.png" alt="Third slide" />
              <div className="text_home">
                <h1>Seastar Andaman</h1>
                <h3>HAPPY JOURNEY</h3>
              </div>
              <div className="NameIsland_home">
                <h4>{t("Home.Similan")}</h4>
                <Row className="Line_detail">
                  <Col sm={6}>
                    {" "}
                    <b>{t("Home.subtitleSimilan")}</b>
                  </Col>
                  <Col sm={6}>
                    <img
                      src="/Img/Homeline.png"
                      alt="#"
                      style={{ width: "163.5%", marginLeft: "-78%" }}
                    />
                  </Col>
                </Row>
              </div>
            </Link>
          </Carousel.Item>

          <Carousel.Item interval={2000}>
            <Link to="/Island13">
              <img className="home-image" src="./Img/5.png" alt="Third slide" />
              <div className="text_home">
                <h1>Seastar Andaman</h1>
                <h3>HAPPY JOURNEY</h3>
              </div>
              <div className="NameIsland_home">
                <h4>{t("Home.Surin")}</h4>
                <Row className="Line_detail">
                  <Col sm={6}>
                    {" "}
                    <b>{t("Home.subtitleSurin")}</b>
                  </Col>
                  <Col sm={6}>
                    <img
                      src="/Img/Homeline.png"
                      alt="#"
                      style={{ width: "165%", marginLeft: "-80%" }}
                    />
                  </Col>
                </Row>
              </div>
            </Link>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
