import React from 'react'
import styled from 'styled-components'

const Body = styled.div`
    position:fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    text-align:center;
    display:flex;
    justify-content:center ;
    align-items:center;
    z-index: 5;
    .my-container{
        border-radius: 25px;
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        max-width: 250px;
        height: 100%;
        max-height: 250px;
        margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width:836px){
        .my-container{
            max-width: 170px;
            max-height: 170px;
        }
    }
    @media screen and (max-width: 420px){
        .my-container{
            max-width: 100px;
            max-height: 100px;
        }
    }
`

export default function Loading() {
    return (
        <Body>
            <div className="my-container">
                <img alt="Loading" src="/Img/Loading_img.gif" />
            </div>
        </Body>
    )
}
