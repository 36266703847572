import React, { Suspense } from "react";
import "./App.css";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Home from "./template/Home";
import Island from "./template/Island";
import Product from "./template/Product";
import About from "./template/About";
import Contact from "./template/Contact";
import Island1 from "./Island/Island1";
import Island2 from "./Island/Island2";
import Island3 from "./Island/Island3";
import Island4 from "./Island/Island4";
import Island5 from "./Island/Island5";
import Island6 from "./Island/Island6";
import Island7 from "./Island/Island7";
import Island8 from "./Island/Island8";
// import Island9 from "./Island/Island9";
import Island10 from "./Island/Island10";
import Island11 from "./Island/Island11";
import Island12 from "./Island/Island12";
import Island13 from "./Island/Island13";
import AccordionTrip from "./template/Accordion_Trip";
import Daytrip from "./template/Daytrip";
import Overnight from "./template/Overnight";
import JamesBond from "./Trip/Daytrip/James_Bond";
import PhiPhiBamboo from "./Trip/Daytrip/PhiPhi_Bamboo";
import PhiPhiKhai from "./Trip/Daytrip/PhiPhi_Khai";
import Rayanoi from "./Trip/Daytrip/Raya_noi";
import RokHaa from "./Trip/Daytrip/Rok_Haa";
import Similan from "./Trip/Daytrip//Similan";
import Surin from "./Trip/Daytrip/Surin";
import Similan2D1N from "./Trip/Overnight/Similan2D1N";
import Surin2D1N from "./Trip/Overnight/Surin2D1N";
import Surin3D2N from "./Trip/Overnight/Surin3D2N";
import Similan2D1Npage2 from "./Trip/Overnight/Similan2D1N_page2";
import Surin2D1Npage2 from "./Trip/Overnight/Surin2D1N_page2";
import Surin3D2Npage2 from "./Trip/Overnight/Surin3D2N_page2";
import Surin3D2Npage3 from "./Trip/Overnight/Surin3D2N_page3";
import Footer from "./component/Footer";

const routes = (
  <Suspense fallback={null}>
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/Island" element={<Island />} />
      <Route path="/Product" element={<Product />} />
      <Route path="/About" element={<About />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/AccordionTrip" element={<AccordionTrip />} />
      <Route path="/Island1" element={<Island1 />} />
      <Route path="/Island2" element={<Island2 />} />
      <Route path="/Island3" element={<Island3 />} />
      <Route path="/Island4" element={<Island4 />} />
      <Route path="/Island5" element={<Island5 />} />
      <Route path="/Island6" element={<Island6 />} />
      <Route path="/Island7" element={<Island7 />} />
      <Route path="/Island8" element={<Island8 />} />
      {/* <Route path="/Island9" element={<Island9 />} /> */}
      <Route path="/Island10" element={<Island10 />} />
      <Route path="/Island11" element={<Island11 />} />
      <Route path="/Island12" element={<Island12 />} />
      <Route path="/Island13" element={<Island13 />} />
      <Route path="/Similan2D1N" element={<Similan2D1N />} />
      <Route path="/Surin3D2N" element={<Surin3D2N />} />
      <Route path="/Surin2D1N" element={<Surin2D1N />} />
      <Route path="/Surin2D1N-2" element={<Surin2D1Npage2 />} />
      <Route path="/Surin3D2N-2" element={<Surin3D2Npage2 />} />
      <Route path="/Surin3D2N-3" element={<Surin3D2Npage3 />} />
      <Route path="/Similan2D1N-2" element={<Similan2D1Npage2 />} />
      <Route path="/Daytrip" element={<Daytrip />} />
      <Route path="/Overnight" element={<Overnight />} />
      <Route path="/James_Bond" element={<JamesBond />} />
      <Route path="/PhiPhi_Khai" element={<PhiPhiKhai />} />
      <Route path="/PhiPhi_Bamboo" element={<PhiPhiBamboo />} />
      <Route path="/Raya_noi" element={<Rayanoi />} />
      <Route path="/Rok_Haa" element={<RokHaa />} />
      <Route path="/Similan" element={<Similan />} />
      <Route path="/Surin" element={<Surin />} />
    </Routes>
    <Footer />
  </Suspense>
);

function App() {
  return (
    <>
      <div className="App">{routes}</div>
    </>
  );
}
export default App;
