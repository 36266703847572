import React from "react";
import { Col, Figure, Row } from "react-bootstrap";
import {
  TimelineContent,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../../component/SelectNavbar";
import HeadNavbar from "../../component/HeadNavbar";
import "../TripCarousel.scss";

export default function Surin3D2N_page3() {
  const { t } = useTranslation();
  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    lazyLoad: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1101,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="Detail_Daytrip" style={{ marginBottom: "20%" }}>
        <div className="Accordion_Trip">
          <Row className="Accordion_Overnight gx-0">
            <Col sm={6}>
              <h1>
                <Figure.Image
                  width={100}
                  height={100}
                  src="/Img/Icon/overnight.png"
                />{" "}
                {t("Overnight")}
              </h1>
            </Col>
          </Row>
        </div>
        <div className="Headname_Island">
          <Row style={{ marginLeft: "-75px" }}>
            <Col sm={1}>
              <div>
                <Link to="/Surin3D2N" className="changepage1">
                  <button class="btnDay1 btn-danger-gradiant mt-1 text-white border-0 px-3 py-1">
                    <b>{t("Button_Day1")}</b>
                  </button>
                </Link>
              </div>
              <div>
                <Link to="/Surin3D2N-2" className="changepage1">
                  <button class="btnDay2 btn-danger-gradiant mt-1 text-white border-0 px-3 py-1">
                    <b>{t("Button_Day2")}</b>
                  </button>
                </Link>
              </div>
              <div>
                <Link to="/Surin3D2N-3" className="changepage1">
                  <button
                    class="btnDay3 btn-danger-gradiant mt-1 text-white border-0 px-3 py-1"
                    style={{ backgroundColor: "#2c92c2" }}
                  >
                    <b style={{ color: "white" }}>{t("Button_Day3")}</b>
                  </button>
                </Link>
              </div>
            </Col>

            <Col sm={11} className="Name_Overnight_trip">
              <h1>{t("Trip.titlename_Surin3D2N")}</h1>
            </Col>
          </Row>
        </div>
        <div className="carousel-photo">
          <Slider {...settings}>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo1.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo2.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo3.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo4.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo5.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo6.jpg" alt="" />
            </div>
            <div className="hover">
              <img src="./Img/Overnight/Surin_3D2N/photo7.jpg" alt="" />
            </div>
          </Slider>
          <div className="recommend">
            <h4>{t("RECOMMEND")}</h4>
            <Row className="recommend-trip">
              <Col sm={6}>
                <div className="detail-island gx-0">
                  <Link to="/Surin2D1N" className="detail-trip">
                    <div className="photo-overlay">
                      <div className="island-surin2d1n"></div>
                    </div>
                    <p>{t("Overnight.Name_Surin2D1N")}</p>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="detail-island gx-0">
                  <Link to="/Similan2D1N" className="detail-trip">
                    <div className="photo-overlay">
                      <div className="island-similan2d1n"></div>
                    </div>
                    <p>{t("Overnight.Name_Similan2D1N")}</p>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="other-trip">
            <a href="/Product">{t("Other Trips")}</a>
          </div>
        </div>
        <div className="detail_trips">
          <Row>
            <Col sm={6}>
              <Timeline className="timeline">
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>08.00AM</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Surin3D2N_Day3_08.00AM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>09.00AM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Surin3D2N_Day3_09.00AM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>12.00PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Surin3D2N_Day3_12.00PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>01.30PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Surin3D2N_Day3_01.30PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>03.30PM.</b>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Surin3D2N_Day3_03.30PM")}</p>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator style={{ paddingTop: "1%" }}>
                    <b>05.00PM.</b>
                  </TimelineSeparator>
                  <TimelineContent>
                    <p>{t("Trip.Surin3D2N_Day3_05.00PM")}</p>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Col>
          </Row>
        </div>
        <div className="Detailname_Island">
          <Col sm={6}>
            <h1>{t("Trip.Condition")}</h1>
          </Col>
        </div>
        <div className="detail_trips">
          <Row>
            <Col sm={6} style={{ padding: "2%" }}>
              <Row>
                <Col sm={6}>
                  <h4 style={{ paddingLeft: "3%" }}>{t("Tour_include")} :</h4>
                  <ul className="Listtext" style={{ listStyle: "none" }}>
                    <li>
                      <p>
                        <Figure.Image
                          width={25}
                          height={25}
                          src="/Img/Icon/land.png"
                        />
                      </p>
                      <p>{t("include_transfer")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={20}
                          height={20}
                          src="/Img/Icon/accommodation.png"
                        />
                      </p>
                      <p>{t("include_Accommodation")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={25}
                          height={25}
                          src="/Img/Icon/coffee.png"
                        />
                      </p>
                      <p>{t("include_drink")}</p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={20}
                          height={20}
                          src="/Img/Icon/EP_guide.png"
                        />
                      </p>
                      <p>{t("include_Professionalguide")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={30}
                          height={30}
                          src="/Img/Icon/accident.png"
                        />
                      </p>
                      <p>{t("include_accident_insurance")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={20}
                          height={20}
                          src="/Img/Icon/fee.png"
                        />
                      </p>
                      <p>{t("include_park_fee")}</p>
                    </li>
                  </ul>
                </Col>

                <Col className="icon_part2" sm={6} style={{ paddingTop: "5%" }}>
                  <ul className="Listtext" style={{ listStyle: "none" }}>
                    <li>
                      <p>
                        <Figure.Image
                          width={30}
                          height={30}
                          src="/Img/Icon/speedboad.png"
                        />
                      </p>
                      <p>{t("Surin.transfer_by_speed")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={20}
                          height={20}
                          src="/Img/Icon/dive.png"
                        />
                      </p>
                      <p>{t("include_Snorkeling")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={20}
                          height={20}
                          src="/Img/Icon/lunch.png"
                        />
                      </p>
                      <p>{t("include_9meals")}</p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={30}
                          height={30}
                          src="/Img/Icon/cpr.png"
                        />
                      </p>
                      <p>{t("include_CPR")} </p>
                    </li>
                    <li>
                      <p>
                        <Figure.Image
                          width={20}
                          height={20}
                          src="/Img/Icon/towel.png"
                        />
                      </p>
                      <p>{t("Surin._Fin&1Towel")} </p>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>

            <Col sm={6}></Col>
          </Row>
        </div>
        <div className="Detailname_Island">
          <Col sm={6} className="whattobring">
            <h1>{t("What_to_bring")} : </h1>
            <p>{t("What_to_bring_detail1")}</p>
          </Col>
        </div>
        <div className="recommend-mobile-size">
          <h4>{t("RECOMMEND")}</h4>
          <Row className="recommend-trip-mobile-size">
            <Col>
              <div className="detail-island-mobile-size gx-0">
                <Link to="/Surin2D1N" className="detail-trip-mobile-size">
                  <div className="photo-overlay-mobile-size">
                    <div className="island-surin2d1n-mobile-size"></div>
                  </div>
                  <p>{t("Overnight.Name_Surin2D1N")}</p>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="detail-island-mobile-size gx-0">
                <Link to="/Similan2D1N" className="detail-trip-mobile-size">
                  <div className="photo-overlay-mobile-size">
                    <div className="island-similan2d1n-mobile-size"></div>
                  </div>
                  <p>{t("Overnight.Name_Similan2D1N")}</p>
                </Link>
              </div>
            </Col>
          </Row>
          <Link to="/Product" className="other-trip">
            {t("Other Trips")}
          </Link>
        </div>
      </div>
    </div>
  );
}
