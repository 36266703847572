import React, { useState, useEffect } from "react";
import { Col, Figure, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import Loading from "../component/Loading";
import { SampleNextArrow, SamplePrevArrow } from "../component/Arrow";
import { Scroll } from "../component/Scroll";
import styled from "styled-components";

const Body = styled.div``;

export default function Daytrip() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const { lockScroll, unlockScroll } = Scroll();

  useEffect(() => {
    if (loading === true) {
      lockScroll();
      setTimeout(() => setLoading(false), 4000);
    } else {
      unlockScroll();
    }
  });

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          rows: 4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          rows: 7,
        },
      },
    ],
  };

  return (
    <Body>
      {loading === false ? <></> : <Loading />}
      <HeadNavbar />
      <SelectNavbar />
      <div className="Select_Accordion_Trip">
        <div className="Accordion_Trip">
          <Link to="/Accordion_Trip" style={{ textDecoration: "none" }}>
            <Row className="Accordion_Daytrip gx-0">
              <Col sm={6}>
                <h1>
                  <Figure.Image
                    width={100}
                    height={100}
                    src="/Img/Icon/daytrip.png"
                  />{" "}
                  {t("Daytrip")}
                </h1>
              </Col>
            </Row>
          </Link>

          <Slider {...settings} className="Detail_Island1 gx-0">
            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/James_Bond">
                  <div className="Island_James_Khai"></div>{" "}
                </Link>
              </div>
              <p>{t("Daytrip.Name_James_Khai")}</p>
            </div>

            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/PhiPhi_Bamboo">
                  <div className="Island_PhiPhi_Bamboo"></div>
                </Link>
              </div>
              <p>{t("Daytrip.Name_PhiPhi_Bamboo")}</p>
            </div>

            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/PhiPhi_Khai">
                  <div className="Island_PhiPhi_Khai"></div>
                </Link>
              </div>
              <p>{t("Daytrip.Name_PhiPhi_Khai")}</p>
            </div>

            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/Raya_noi">
                  <div className="Island_Raya_noi"></div>
                </Link>
              </div>
              <p>{t("Daytrip.Name_Raya_noi")}</p>
            </div>

            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/Rok_Haa">
                  <div className="Island_Rok_Haa"></div>{" "}
                </Link>
              </div>
              <p>{t("Daytrip.Name_Rok_Haa")}</p>
            </div>

            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/Similan">
                  <div className="Island_Similan"></div>
                </Link>
              </div>
              <p>{t("Daytrip.Name_Similan")}</p>
            </div>

            <div className="detail_trip1">
              <div className="photo_overlay">
                <Link to="/Surin">
                  <div className="Island_Surin"></div>
                </Link>
              </div>
              <p>{t("Daytrip.Name_Surin")}</p>
            </div>
          </Slider>
        </div>
        <Link to="/Overnight" className="Button_Overnight">
          <div className="BoxOvernight">
            <h2>
              <Figure.Image
                width={50}
                height={50}
                src="/Img/Icon/overnight_blue.png"
              />{" "}
              {t("Overnight")}
            </h2>
            <div class="text_overlay">
              <p>{t("Clicktoseedetail")}</p>
            </div>
          </div>
        </Link>
      </div>
    </Body>
  );
}
