import React, { useState } from "react";
import { Row, Collapse, Col, Figure } from "react-bootstrap";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";

export default function About() {
  const { t } = useTranslation();
  const [open1, setOpen1] = useState(false);
  const [isShow, setIsShowLess] = useState(true);
  function toggleIsShow() {
    setIsShowLess(!isShow);
    setOpen1(!open1);
  }
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="About">
        <div className="boxabout ">
          <Row>
            <Col md={6}>
              <img className="line" src="/Img/line1.png" alt="#" />
              <Figure>
                <Figure.Image className="Figure_img" src="logo1.png" />
              </Figure>
              <img className="line" src="/Img/line2.png" alt="#" />
            </Col>

            <Col md={6} className="textabout ">
              <h1>{t("Abouttitle")}</h1>
              <h3>{t("Seastar_andaman")}</h3>
              <Figure>
                <Figure.Image className="Figure_img1" src="logo1.png" />
              </Figure>
              <span className="wordblock">
                {t("About.detail_part1")}
                <Collapse in={open1}>
                  <span className="wordblock">{t("About.detail_part2")}</span>
                </Collapse>
              </span>

              <button
                onClick={toggleIsShow}
                class="btnReadmore btn-danger-gradiant mt-3 text-white border-0 px-3 py-2"
                aria-controls="example-collapse-text"
                aria-expanded={open1}
              >
                <b>
                  {isShow ? (
                    <div>
                      <b>{t("About.Read_more")}</b> <AiOutlineDown />
                    </div>
                  ) : (
                    <div>
                      <b>{t("About.Hide_Detail")}</b> <AiOutlineUp />
                    </div>
                  )}
                </b>
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
