import React from "react";
import { Col, Figure, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectNavbar from "../component/SelectNavbar";
import HeadNavbar from "../component/HeadNavbar";
import "./Trip.scss";

export default function Island1() {
  const { t } = useTranslation();
  return (
    <div>
      <HeadNavbar />
      <SelectNavbar />
      <div className="Island">
        <Row sm={12} className="Head_Island1 gx-0">
          <Col sm={5}>
            <h1>{t("Island.Name_JamesBond")}</h1>
          </Col>
          <Col sm={3} className="text_overlay">
            <b>{t("Island.Detail_JamesBond")}</b>
          </Col>
        </Row>

        <h2>
          {t("PRODUCT_HAVE")} {t("Island.Name_JamesBond")}
        </h2>

        <div className="detail-island gx-0">
          <Link to="/James_Bond" className="detail-trip">
            <div className="photo-overlay">
              <div className="island-james-khai">
                <div class="text-overlay">
                  <div class="trip">
                    <Figure.Image
                      width={50}
                      height={50}
                      src="/Img/Icon/daytrip.png"
                    />
                    <br />
                    <b>{t("Daytrip")}</b>
                  </div>
                </div>
              </div>
            </div>
            <p>{t("Daytrip.Name_James_Khai")}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
