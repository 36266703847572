import axios from "axios";

let HEADER = {
  "Content-Type": "application/json",
  withCredentials: true,
};

// const BASE_URL = "http://localhost:5001/seastar-andaman-2022/asia-southeast2/";
const BASE_URL =
  "https://asia-southeast2-seastar-andaman-2022.cloudfunctions.net/";

const Provider = async (options = { type: "", url: "", data: null }) => {
  const { type, url, data } = options;
  let result;
  /* if (token) {
        HEADER = {
            ...HEADER,
            'x-has-accesstoken': token
        }
    } */
  const instanceMain = axios.create({
    baseURL: BASE_URL,
    headers: HEADER,
    transformResponse: [
      function apiData(data) {
        const parseData = JSON.parse(data);
        if (parseData === null) {
          return parseData;
        }
        if (parseData.msg !== undefined) {
          if (parseData.msg === "Failed to send message") {
            window.location = `${BASE_URL}/403`;
          }
        }
        return parseData;
      },
    ],
  });
  if (type === "GET" || type === "SHOW") result = await instanceMain.get(url);
  else if (type === "POST") result = await instanceMain.post(url, data);
  else if (type === "PUT") result = await instanceMain.put(url, data);
  else if (type === "DELETE") result = await instanceMain.delete(url);
  return result.data;
};

export default Provider;
